<template>
  <div class="oder_wrapper">
    <div>
      <h2 class="flexa">订单管理</h2>
    </div>
    <header class="flex">
      <div class="flex">
        <div class="flexs">
          <p class="marR10">状态：</p>
          <el-select v-model="page.productStatus" @change="selectRemark" placeholder="全部状态" size="mini" clearable class="select_input img w150 marR20">
            <el-option label="售卖中" value="0"></el-option>
            <el-option label="已下架" value="1"></el-option>
          </el-select>
        </div>
        <div class="flex marR20">
          <el-input v-model="page.productName" placeholder="请输入产品名称" size="mini" clearable></el-input>
          <el-button type="primary" size="mini" @click="getMyProducts">查询
          </el-button>
        </div>
      </div>
      <el-button type="primary" class="publish" size="mini" @click="goRouter('/center/product/')">发布产品
      </el-button>
    </header>
    <section>
      <el-table :data="tableData" :header-cell-style="{ 'text-align': 'center' }" :cell-style="{ 'text-align': 'center' }">
        <el-table-column label="封面图">
          <template slot-scope="scope">
            <img :src="scope.row.productImage" alt="" class="imgd" />
          </template>
        </el-table-column>
        <el-table-column prop="productName" label="产品名称" width="220">
        </el-table-column>

        <el-table-column prop="productPrice" label="价格"></el-table-column>
        <el-table-column prop="createDate" label="上传时间" width="200">
          <template slot-scope="scope">
              {{$dateFormat(scope.row.createDate, 'YYYY/MM/DD HH:mm:ss')}}
          </template>
        </el-table-column>
        <el-table-column label="审核状态">
          <template slot-scope="scope">
            <p v-if="scope.row.auditStatus == 0" class="p1">待审核</p>
            <p v-if="scope.row.auditStatus == 1" class="p2">审核通过</p>
            <p v-if="scope.row.auditStatus == 2" class="p3">审核不通过</p>
          </template>
        </el-table-column>
        <el-table-column label="商品状态">
          <template slot-scope="scope">
            <p v-if="scope.row.productStatus == 0" class="p1">售卖中</p>
            <p v-if="scope.row.productStatus == 1" class="p2">下架</p>
          </template>
        </el-table-column>
        <el-table-column prop="address" label="操作">
          <template slot-scope="scope">
            <el-button type="text" @click="goRouter('/center/product/',scope.row.id)">
              <p class="p4">编辑</p>
            </el-button>
            <el-button type="text" @click="handleClick(scope.row.id, scope.row.productStatus)">
              <p class="p4">{{ scope.row.productStatus == 0 ? '下架' : '上架' }}</p>
            </el-button>
            <el-button type="text" @click="deleteMyProducts(scope.row.id)">
              <p class="p4">删除</p>
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <status type="post" des="您还未发布产品~" v-if="this.tableData == null || this.tableData.length <= 0"></status>
      <div class="pages">
        <el-pagination background class="work_pagination" @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="page.pageNum" :page-size="page.pageSize" layout="prev, pager, next, total, jumper" :total="page.total"></el-pagination>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      page: {
        pageNum: 1,
        pageSize: 9,
        productStatus: '',
        productName: '',
      },
      tableData: [],
      memberType: '',
      url: '',
    }
  },
  watch: {
    page: {
      handler(newName, oldName) {
        this.getMyProducts()
      },
      deep: true,
    },
  },
  methods: {
    async getMyProducts() {
      var userInfo = localStorage.getItem('userInfo')
      userInfo = userInfo ? JSON.parse(userInfo) : {}
      let res = await this.$axios.post('/api/app-jycy-gansulz/product/list', {
        disabledId: userInfo.id,
        ...this.page,
      })
      this.tableData = res.data.data.records;
      this.page.total = res.data.data.total
    },
    selectRemark(value) {
      this.page.productStatus = value
      this.page.pageNum = 1
      this.getMyProducts()
    },
    deleteMyProducts(id) {
      this.$axios
        .get('/api/app-jycy-gansulz/product/del?id=' + id)
        .then((res) => {
          if (res.data.success) {
            this.$confirm('确定删该产品吗?一旦删除不可恢复', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning',
            })
              .then(() => {
                this.$message.success(res.data.msg)
                this.getMyProducts()
              })
              .catch((err) => {
                console.log(err)
              })
          }
        })
    },
    handleClick(id, productStatus) {
      this.$axios
        .post('/api/app-jycy-gansulz/product/save', {
          id: id,
          productStatus: productStatus == 0 ? '1' : '0',
        })
        .then((res) => {
          if (res.data.success) {
            this.$confirm('确定要进行此操作吗?', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning',
            })
              .then(() => {
                this.$message.success(res.data.msg)
                this.getMyProducts()
              })
              .catch((err) => {
                console.log(err)
              })
          } else {
            this.$message.error(res.data.msg)
          }
        })
    },
    goRouter(url, id) {
      const { href } = this.$router.resolve({
        path: url,
        query: {
          id: id,
        },
      })
      window.open(href, '_blank')
    },
    //分页
    handleCurrentChange: function (val) {
      this.page.pageNum = val
      window.scrollTo(0, 0)
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`)
    },
  },
  created() {
    this.getMyProducts()
    if (this.$store.state.login) {
      let userInfo = this.$store.state.login.userInfo
      this.memberType = userInfo.memberType
      console.log(this.memberType)
      if (this.memberType == '1') {
        this.url = '/center/product/'
      } else {
        this.url = '/enterprise/product/'
      }
    }
  },
}
</script>

<style lang="less" scoped>
@import './css/produce';
/deep/.work_pagination{
    .btn-prev,.el-pager li, .btn-next, .el-input__inner{
      background: rgba(0,18,10,0.05);
    }
    .el-pagination__editor.el-input{
      width: 48px;
      height: 25px;
    }
  }
</style>
